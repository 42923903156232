/* Add here all your JS customizations */

$('#tabela-aprovados').DataTable({
    "language" : {
        "url": "../Portuguese-Brasil.json"
    }
});

$('#tabela-analise').DataTable();
$('#tabela-cadastrados').DataTable();
$('#tabela-resgatados').DataTable();
$('#tabela-cancelados').DataTable();

$("#bancos").chosen({
    no_results_text: "Sem ocorrências encontradas.",
    placeholder_text_single: "Selecione um banco"
});

//jQuery Mask Plugin
var maskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00000';
    },
    options = {onKeyPress: function(val, e, field, options) {
        field.mask(maskBehavior.apply({}, arguments), options);
    }
    };

$('.phone_with_ddd').mask(maskBehavior, options);
$('.data_nasc').mask('00/00/0000', {placeholder: "__/__/____"});
$('.voucher_codigo').mask('00000');
$('.voucher_numero').mask('0000000000000000');
$('.voucher_valor').mask('00000000');











